import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { makeApiRequest } from "../AxiosServices/apiCall";
import { ToastContainer, toast } from "react-toastify";
import Dashboard from './Dashboard';
import { Spinner } from 'react-bootstrap';
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import React, { useState, useEffect, useRef } from 'react';



function Uploadproduct() {
    const [defaultData, setDefaultData] = useState({ id: "", name: "" })
    const [selectedOption, setSelectedOption] = useState(defaultData.name);
    const [selectedSubOption, setSelectedSubOption] = useState("")
    const Navigate = useNavigate()
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([])
    const [categoryId, setCategoryId] = useState("")
    const [subCategoryId, setSubCategoryId] = useState("")
    const [mainImagePreview, setMainImagePreview] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenSkinCare, setIsOpenSkinCare] = useState(false);
    const [gst, setGst] = useState("")
    const fileInputRef = useRef(null); // New ref to control the file input
    const [totalFiles, setTotalFiles] = useState(0)
    const handleBrowseFile = useRef();
    

    const options = category.map((data) => {
        return {
            value: data.name,
            label: data.name,
            id: data._id
        }
    })

    const skinCareOptions = subCategory.map((data) => {
        return {
            value: data.name,
            label: data.name,
            id: data._id
        }
    })

    const handleSelectOption = (option) => {
        setSelectedOption(option.value);
        setCategoryId(option.id)
        formik.setFieldValue('category', option.value);
        formik.setFieldValue('categoryId', option.id);
        setIsOpenSkinCare(false);
    };

    const handleSubOption = (option) => {
        setSelectedSubOption(option.value);
        setSubCategoryId(option.id)
        formik.setFieldValue('subCategory', option.value);
        formik.setFieldValue('subCategoryId', option.id);
    };

    const formik = useFormik({
        initialValues: {
            productname: '',
            description: '',
            price: '',
            Gstprice: '',
            category: '',
            categoryId: "",
            subCategory: '',
            subCategoryId: "",
            shade: '',
            quantity: '',
            discount: '',
            finalprice: '',
            size: '',
            media: [],
        },
        validationSchema: Yup.object({
            productname: Yup.string().required('*Productname is required'),
            description: Yup.string().required('*Description is required'),
            price: Yup.number()
                .typeError('*Price must be a number') // Ensure the type is a number
                .positive('*Price must be a positive number')
                .min(0, '*Price must be a positive number') // Ensure it is greater than 0
                .required('*Price is required'),
            Gstprice: Yup.number()
                .typeError('*Gstprice must be a number') // Ensure the type is a number
                .positive('*Gstprice must be a positive number')
                .min(0, '*Gstprice must be a positive number') // Ensure it is greater than 0
                .required('*Gstprice is required'),
            quantity: Yup.number()
                .typeError('*Quantity must be a number') // Ensure the type is a number
                .positive('*Quantity must be a positive number')
                .min(0, '*Quantity must be a positive number') // Ensure it is greater than 0
                .required('*Quantity is required'),
            // shade: Yup.string().required('*Shade is required'),
            size: Yup.string().required('*Size is required'),
            media: Yup.array().min(1, '*At least one media file is required').test(
                'media-type',
                '*Only jpeg, jpg, png images and mp4 videos are allowed',
                (files) => {
                    return files.every(file => {
                        const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
                        const validVideoTypes = ['video/mp4'];

                        if (file.type.startsWith('image/')) {
                            return validImageTypes.includes(file.type);
                        } else if (file.type.startsWith('video/')) {
                            return validVideoTypes.includes(file.type);
                        }
                        return false;
                    });
                }
            ).test(
                'media-size',
                '*Each image must be less than 2MB and each video less than 30MB',
                (files) => {
                    return files.every(file => {
                        if (file.type.startsWith('image/')) {
                            return file.size <= 2 * 1024 * 1024; // 2MB in bytes
                        } else if (file.type.startsWith('video/')) {
                            return file.size <= 30 * 1024 * 1024; // 30MB in bytes
                        }
                        return false;
                    });
                }
            ),
            category: Yup.string().required('*Category is required'),
            subCategory: Yup.string().required('*Subcategory is required'),
        }),
        onSubmit: async (values) => {
            let discountprice;
            discountprice = values.Gstprice * (values.discount / 100);
            discountprice = Math.round(discountprice)

            const formData = new FormData();
            formData.append('productname', values.productname);
            formData.append('description', values.description);
            formData.append('price', values.price);
            formData.append('Gstprice', values.Gstprice);
            formData.append('category', values.category);
            formData.append('categoryId', values.categoryId);
            formData.append('subCategory', values.subCategory);
            formData.append('subCategoryId', values.subCategoryId);
            formData.append('shade', values.shade);
            formData.append('quantity', values.quantity);
            formData.append('discount', values.discount);
            formData.append('discount_price', discountprice);
            formData.append('finalprice', values.finalprice);
            formData.append('size', values.size);

            (values.media).forEach(file => {
                formData.append('media', file);
            });

            try {
                setIsLoading(true);
                const params = {
                    url: `add&update-productData`,
                    method: "POST",
                    data: formData,
                    header: "image",
                };
                const response = await makeApiRequest(params);
                if (response.status === true) {
                    setIsLoading(false);
                    toast.success(response.message);
                    setTimeout(() => {
                        Navigate('/dashboard/product');
                    }, 1000);
                } else {
                    toast.warn(response.message);
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                toast.error('Error: ' + error.message);
            }
        }
    });

    const handleChange = (e) => {
        formik.handleChange(e);

    };

    useEffect(() => {
        let priceValue = formik.values.price;
        let discount = formik.values.discount || 0
        let finalPrice = 0;
        let gstprice = 0;

        // convert gst price
        if (formik.values.price > 0 && formik.values.price != "") {
            const GstCal = priceValue * (gst / 100);
            gstprice = Number(priceValue) + Number(GstCal)
            formik.setFieldValue('Gstprice', Math.round(gstprice))
        }
        else {
            formik.setFieldValue('Gstprice', "")
        }

        // final price include discount
        if (formik.values.price > 0 && formik.values.discount > 0 && formik.values.discount != "" && formik.values.price != "") {
            const Gst = Math.round(gstprice * Number(discount / 100));
            finalPrice = gstprice - Gst;
            formik.setFieldValue('finalprice', Math.round(finalPrice));
        } else {
            formik.setFieldValue('finalprice', Math.round(gstprice));
        };
    }, [formik.values]);

    const handleMainImageChange = (event) => {
        const files = Array.from(event.currentTarget.files);

        if (files.length + formik.values.media.length > 10) {
            toast.warn('You can only upload a maximum of 10 files.');
            return;
        }

        const updatedFiles = [...formik.values.media, ...files];
        console.log(updatedFiles, "updatedFiles",);
        setTotalFiles(updatedFiles.length)

        formik.setFieldValue('media', updatedFiles);

        const updatedPreviews = updatedFiles.map((file) => ({
            url: URL.createObjectURL(file),
            type: file.type
        }));

        setMainImagePreview(updatedPreviews);
    };



    const handleRemoveMainImage = (index) => {
        const updatedMainImages = formik.values.media.filter((_, i) => i !== index);
        const updatedMainPreview = mainImagePreview.filter((_, i) => i !== index);

        console.log(updatedMainPreview);
        setTotalFiles(updatedMainImages.length)


        formik.setFieldValue('media', updatedMainImages);
        setMainImagePreview(updatedMainPreview);


    };

    const getCategories = async () => {
        try {
            let params = {
                url: `getcategories`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status === true) {

                const { data: categoriesList } = response;
                const { _id: listID, name: listName } = categoriesList[0];
                setGst(response.Gst)
                setDefaultData({ ...defaultData, id: listID, name: listName });
                setCategory(categoriesList);
                const selectedCategoryData = categoriesList.find(({ name }) => name === selectedOption);
                const defaultCategory = categoriesList.find(({ name }) => name === listName);

                if (!selectedCategoryData) {
                    setCategoryId(listID);
                    setSelectedOption(listName);
                    setSubCategory(defaultCategory.subCategories);
                } else {
                    setCategoryId(selectedCategoryData._id);
                    setSelectedOption(selectedCategoryData.name);
                    setSubCategory(selectedCategoryData.subCategories);
                }
            } else {
                setCategory([]);
                setSubCategory([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        getCategories();
    }, [selectedOption]);

    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-uploadproduct min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <Dashboard />
                                </div>
                                <div className='col-md-12 ms-sm-auto col-lg-10 px-md-4 m-0 p-0'>
                                    <h2 className='fw-bold'><a href='/dashboard/product'><IoArrowBackCircleOutline className='me-2' style={{ color: "rgb(235 138 152)" }} /></a>Add Product</h2>
                                    <div className='custom-inside-upload mt-4'>
                                        <div className='row mb-3'>
                                            <div className="col-sm-4 mt-4">
                                                <Select
                                                    options={options}
                                                    isOpen={isOpenSkinCare}
                                                    onChange={handleSelectOption}
                                                    name="category"
                                                    value={options.find(option => option.value && option.id === selectedOption)}
                                                    placeholder="Select an option"
                                                />
                                                {formik.touched.category && formik.errors.category ? (
                                                    <div className="text-danger">{formik.errors.category}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    {selectedOption && (
                                        <div className='custom-skin-care-option'>
                                            <h2> {selectedOption} </h2>
                                            <div className='custom-skincare'>
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputName" className="col-sm-3 col-form-label text-lg-end">Product Name</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="productname"
                                                                value={formik.values.productname}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputName"
                                                                placeholder='ProductName'
                                                            />
                                                            {formik.touched.productname && formik.errors.productname ? (
                                                                <div className="text-danger">{formik.errors.productname}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputDescription" className="col-sm-3 col-form-label text-lg-end">Description</label>
                                                        <div className="col-sm-6">
                                                            <textarea
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="description"
                                                                value={formik.values.description}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputDescription"
                                                                placeholder='Product Description'
                                                            />
                                                            {formik.touched.description && formik.errors.description ? (
                                                                <div className="text-danger">{formik.errors.description}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputPrice" className="col-sm-3 col-form-label text-lg-end">Price</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="price"
                                                                value={formik.values.price}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputPrice"
                                                                placeholder='Price'
                                                            />
                                                            {formik.touched.price && formik.errors.price ? (
                                                                <div className="text-danger">{formik.errors.price}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="Gstprice" className="col-sm-3 col-form-label text-lg-end">Gst Price({gst}%)</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="Gstprice"
                                                                value={formik.values.Gstprice}
                                                                readOnly
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="Gstprice"
                                                                placeholder='Gst price'
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputDiscount" className="col-sm-3 col-form-label text-lg-end">Discount Percentage(%)</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="discount"
                                                                value={formik.values.discount}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputDiscount"
                                                                placeholder='Discount Percentage'
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="finalprice" className="col-sm-3 col-form-label text-lg-end">Final Price</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="finalprice"
                                                                value={formik.values.finalprice}
                                                                readOnly
                                                                id="finalprice"
                                                                placeholder='Final Price'
                                                            />
                                                            {formik.touched.finalprice && formik.errors.finalprice ? (
                                                                <div className="text-danger">{formik.errors.finalprice}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className='row mb-3'>
                                                        <label htmlFor="dropdownSkinCare" className="col-sm-3 col-form-label text-lg-end">Select a {selectedOption} Option:</label>
                                                        <div className="col-sm-6">
                                                            <Select
                                                                options={skinCareOptions}
                                                                name="subCategory"
                                                                onChange={handleSubOption}
                                                                placeholder="Select an option"
                                                                value={skinCareOptions.find(option => option.id === formik.values.subCategoryId)}
                                                            />
                                                            {formik.touched.subCategory && formik.errors.subCategory ? (
                                                                <div className="text-danger">{formik.errors.subCategory}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className='row mt-4'>
                                                        <label htmlFor='formFileMain' className='form-label col-sm-3 text-lg-end'>
                                                            Images/Videos
                                                        </label>
                                                        <div className='col-sm-6'>
                                                            <div style={{ border: "2px solid black", width: "100%", height: "35px", cursor: "pointer" }} onClick={() => {
                                                                if (handleBrowseFile && handleBrowseFile.current) {
                                                                    handleBrowseFile.current.click();
                                                                }
                                                            }}>{totalFiles > 0 ? `${totalFiles} File Choosen` : "Click to open browse collection"}</div>
                                                            <input
                                                                className='form-control'
                                                                type='file'
                                                                id='formFileMain'
                                                                name='productImage'
                                                                multiple
                                                                accept='.jpeg,.jpg,.png,.mp4'
                                                                onChange={handleMainImageChange}
                                                                ref={handleBrowseFile}
                                                                hidden
                                                            />
                                                            {formik.touched.media && formik.errors.media ? (
                                                                <div className='text-danger'>{formik.errors.media}</div>
                                                            ) : null}
                                                            <div className='image-preview mt-3'>
                                                                {mainImagePreview.map((filePreview, index) => (
                                                                    <div key={index} className='preview-container'>
                                                                        {filePreview.type.startsWith('image/') ? (
                                                                            <img width="100" src={filePreview.url} alt={`main-preview-${index}`} className='img-thumbnail' />
                                                                        ) : filePreview.type.startsWith('video/') ? (
                                                                            <video width="100" controls>
                                                                                <source src={filePreview.url} type={filePreview.type} />
                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                        ) : null}
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-danger btn-sm'
                                                                            onClick={() => handleRemoveMainImage(index)}
                                                                        >
                                                                            <IoMdRemoveCircleOutline />
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3 mt-4">
                                                        <label htmlFor="inputShade" className="col-sm-3 col-form-label text-lg-end">Fragrance</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="shade"
                                                                value={formik.values.shade}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputShade"
                                                                placeholder='Enter Fragrance'
                                                            />
                                                            {formik.touched.shade && formik.errors.shade ? (
                                                                <div className="text-danger">{formik.errors.shade}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputQuantity" className="col-sm-3 col-form-label text-lg-end">Stock Limit</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="quantity"
                                                                value={formik.values.quantity}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputQuantity"
                                                                placeholder='Enter Stock Limit'
                                                            />
                                                            {formik.touched.quantity && formik.errors.quantity ? (
                                                                <div className="text-danger">{formik.errors.quantity}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label htmlFor="inputSize" className="col-sm-3 col-form-label text-lg-end">Quantity (ml or grm)</label>
                                                        <div className="col-sm-6">
                                                            <input
                                                                type="text"
                                                                className="form-control input-text-2"
                                                                name="size"
                                                                value={formik.values.size}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="inputSize"
                                                                placeholder='Enter Quantity'
                                                            />
                                                            {formik.touched.size && formik.errors.size ? (
                                                                <div className="text-danger">{formik.errors.size}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className='text-center mt-4'>
                                                        {
                                                            isLoading == true ? (
                                                                <button className="liquidity-btn-1" >
                                                                    <Spinner animation="border" />
                                                                </button>
                                                            ) : (
                                                                <button className='liquidity-btn-1' type='submit'>Submit</button>
                                                            )
                                                        }

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Uploadproduct;
