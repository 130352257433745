import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component'
import { makeApiRequest } from "../AxiosServices/apiCall";
import { ToastContainer, toast } from 'react-toastify';

function Influencer() {

    const [influencer, setInfluencer] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [influencerDetail, setInfluencerDetail] = useState({})
    const [clickedData, setClickedData] = useState(null);
    const [type, setType] = useState("")
    const [discount, setDiscount] = useState(0)
    const [typeValue, setTypeValue] = useState("")
    const [defaultStatus, setDefaultStatus] = useState("")
    const [couponStatus, setCouponStatus] = useState(true)
    const [id, setId] = useState("")
    const [promoCode, setPromoCode] = useState("");

    // Handle page change
    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const columnsone = [
        {
            name: 'S.no',
            selector: (row, index) => index + 1,
            sortable: true,
            width: "100px",
            id: 'sno',
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: "150px",
            id: 'name',
        },
        {
            name: 'Email',
            selector: row => {
                const email = row.email;
                const displayEmail = email.length > 20 ? `${email.slice(0, 20)}...` : email;
                return `<a href="mailto:${row.email}" class="email-link">${displayEmail}</a>`;
            },
            sortable: true,
            width: "200px",
            id: 'email',
            cell: (row) => {
                const email = row.email;
                const displayEmail = email.length > 20 ? `${email.slice(0, 20)}...` : email;
                return (
                    <a href={`mailto:${row.email}`} className="email-link">{displayEmail}</a>
                );
            }
        },
        {
            name: 'Contact',
            selector: row => {
                // Display contacts as a concatenated string
                const contacts = row.contact || {};
                return Object.keys(contacts)
                    .filter(key => contacts[key]) // Only include non-empty values
                    .map(key => `${key}: ${contacts[key]}`)
                    .join(', ');
            },
            width: "200px",
            id: 'contact',
        },
        {
            name: 'View Details',
            width: "100px",
            cell: (row) => (
                <button
                    onClick={() => handleViewClick(row)}
                    style={{
                        padding: '5px 10px',
                        backgroundColor: '#e98d9d',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                    type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                >
                    View Details
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Status',
            width: "300px", // Adjust the width as needed
            cell: (row) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginBottom: '5px', marginRight: "10px" }}>
                        {row.influencerStatus == 'pending' ? 'Pending' : row.influencerStatus}
                    </span>
                    <button
                        onClick={() => handleChange(row)}
                        style={{
                            padding: '5px 10px',
                            backgroundColor: row.influencerStatus === "Approved" ? 'rgb(233, 141, 157)' : '#e98d9d',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            // cursor: row.influencerStatus === "Approved" ? 'not-allowed' : 'pointer'
                        }}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop-top"
                    // disabled={row.influencerStatus === "Approved"}
                    >
                        Update Data
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Coupon Code',
            selector: row => row.promoCode ? row.promoCode : "--",
            sortable: true,
            width: "150px",
            id: 'name',
        },
        {
            name: 'Discount',
            selector: row => row.CouponDiscount ? `${row.CouponDiscount}%` : "--",
            sortable: true,
            width: "150px",
            id: 'name',
        },
        {
            name: 'Coupon Status',
            selector: row => row.couponStatus ? "Active" : "Deactive",
            cell: (row) => (
                row.influencerStatus === "Approved" ? (
                    <button
                        onClick={() => {
                            setType("status");
                            const newStatus = !row.couponStatus;
                            setPromoCode(row.promoCode)
                            setCouponStatus(newStatus);
                            setId(row._id);
                            buttonClicked(row);
                        }}
                        style={{
                            padding: '5px 10px',
                            backgroundColor: '#e98d9d',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                        type="button"
                    >
                        {row.couponStatus ? "Active" : "De-Active"}
                    </button>
                ) : ("--")
            ),
            sortable: true,
            id: 'Coupon Status',
        }

    ];

    const buttonClicked = async (row) => {
        setClickedData(row)
    }

    const handleViewClick = async (row) => {
        setInfluencerDetail(row)
    };

    const handleChange = async (row) => {
        setType("offer")
        setDefaultStatus(row.influencerStatus)
        setDiscount(row.CouponDiscount)
        setId(row._id)
    }

    const getInfluencer = async () => {
        try {
            let params = {
                url: `getinfluencer`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);
            if (response.status === true) {
                const InfluencerList = response.data;
                setInfluencer(InfluencerList);
            } else {
                setInfluencer([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }




    useEffect(() => {
        getInfluencer();
        if (clickedData && type == "status") {
            handleSubmit(clickedData)
        }
    }, [clickedData, type])
    const filteredInfluence = influencer.filter((b) => {
        const searchLower = searchQuery.toLowerCase();

        return (
            b.name?.toLowerCase().includes(searchLower)
            || b.email?.toLowerCase().includes(searchLower)
            // || b.contact?.toLowerCase().includes(searchLower)
            || b.youtubelink?.toLowerCase().includes(searchLower)
            || b.instagramid?.toLowerCase().includes(searchLower)
            || b.facebookid?.toLowerCase().includes(searchLower)
            || b.telegramid?.toLowerCase().includes(searchLower)
            || b.influencerStatus?.toLowerCase().includes(searchLower)
            // Check couponStatus field separately to avoid interference with string-based searches
            || (searchLower === 'true' && b.couponStatus === true)
            || (searchLower === 'false' && b.couponStatus === false)
        );
    });


    const handleSubmit = async () => {
        try {
            let datas
            if (type == "offer") {
                datas = {
                    type: type,
                    id: id,
                    discount: discount,
                    value: typeValue
                }
            } else if (type == "status") {
                datas = {
                    type: type,
                    id: id,
                    status: couponStatus,
                    promoCode: promoCode
                }
            }
            let params = {
                url: "influencerUpdate",
                method: "POST",
                data: datas
            }
            const resp = await makeApiRequest(params)
            if (resp.status == true) {
                toast.success(resp.message)
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            } else {
                toast.error(resp.message)
            }
        } catch (error) {
            toast.error("Something Went Wrong")
        }

    }

    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-userlist min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='custom-blog-inside'>
                                <h2 className='fw-bold'>Influencer</h2>
                                <div className='custom-blog-search d-lg-flex align-items-baseline'>
                                    <input
                                        type="text"
                                        id="inputPassword6"
                                        class="form-control input-text-2"
                                        aria-describedby="passwordHelpInline"
                                        placeholder="Search by Name&Status"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='liquidity-table-1 mt-3'>
                                <DataTable
                                    columns={columnsone}
                                    data={filteredInfluence}
                                    theme="solarized"
                                    defaultSortAsc={true}
                                    pagination
                                    paginationTotalRows={influencer.length}
                                    paginationPerPage={rowsPerPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                    onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                                    onChangePage={handlePageChange}
                                    highlightOnHover
                                    dense />
                            </div>
                            <div class="modal fade influencer-modal"id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered "  >
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5 fw-bold" id="staticBackdropLabel">Influencer Details</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <div className="custom-productmodal-1 me-2">
                                                        <div className='row'>
                                                            <div className='col-lg-12'>
                                                                <div style={{overflow:"auto"}}>
                                                                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th> Promo-Code </th>
                                                                                <td>{influencerDetail.promoCode}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Youtube Link</th>
                                                                                <td>{influencerDetail.youtubelink}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Youtube Subscribers</th>
                                                                                <td>{influencerDetail.youtubesubscribers}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Instagram Id</th>
                                                                                <td>{influencerDetail.instagramid}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Insta Followers</th>
                                                                                <td>{influencerDetail.instafollowers}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Facebook Id</th>
                                                                                <td>{influencerDetail.facebookid}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Facebook Followers</th>
                                                                                <td>{influencerDetail.facebookfollowers}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Telegram Id</th>
                                                                                <td>{influencerDetail.telegramid}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Telegram Subscribers</th>
                                                                                <td>{influencerDetail.telegramsubscribers}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Influencer Status</th>
                                                                                <td>{influencerDetail.influencerStatus}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Coupon Discount</th>
                                                                                <td>{influencerDetail.CouponDiscount}% OFFER</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Coupon Status</th>
                                                                                <td>{influencerDetail.couponStatus === true ? "Active" : "De-Active"}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal fade" id="staticBackdrop-top" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Discount</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className='row'>
                                                <div className='col-lg-12 '>
                                                    <div className="custom-productmodal-1 me-2">
                                                        <div className='row'>
                                                            <div className='col-lg-12'>
                                                                <p className='m-0'> Influencer Status: &nbsp;
                                                                    <select
                                                                        value={defaultStatus}
                                                                        onChange={(e) => {
                                                                            setDefaultStatus(e.target.value);
                                                                            setTypeValue(e.target.value);
                                                                        }}

                                                                        style={{
                                                                            padding: '5px 10px',
                                                                            backgroundColor: '#e98d9d',
                                                                            color: 'white',
                                                                            border: 'none',
                                                                            borderRadius: '5px',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    >
                                                                        <option value=""> Select Option</option>
                                                                        <option value="Approve">Approve</option>
                                                                        <option value="Reject">Reject</option>
                                                                    </select>
                                                                </p> <br />

                                                                <div>
                                                                    <label>
                                                                        Discount in %:
                                                                    </label>
                                                                    &nbsp;&nbsp;
                                                                    <input
                                                                        type='number'
                                                                        onChange={(e) => setDiscount(e.target.value)}
                                                                        value={discount}
                                                                    />
                                                                </div>

                                                                <br />
                                                                <div className='text-center mt-3'>
                                                                    <button className='liquidity-btn-1' onClick={handleSubmit}> Submit </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Influencer