import React, { useState, useEffect, useRef } from 'react';
import Dashboard from './Dashboard';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeApiRequest } from "../AxiosServices/apiCall";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { IoArrowBackCircleOutline } from "react-icons/io5";


function Faqaddblogs() {

    const quillRef = useRef(null);
    const Navigate = useNavigate()
 
    const formik = useFormik({
        initialValues: {
            faqTitle: '',
            faqDescription: '',
            
        },
        validationSchema: Yup.object({
            faqTitle: Yup.string().required('*FaqTitle is required'),
            faqDescription: Yup.string().required('*Faq Description is required'),
        }),
        onSubmit: async (values) => {
            const formData = {
                faqTitle: values.faqTitle,
                faqDescription: values.faqDescription,
                type: 'create' 
            };

            try {
                const params = {
                    url: `faqUpdate`,
                    method: "POST",
                    data: formData,
                };

                const response = await makeApiRequest(params);
                // console.log('response----',response);
                
                if (response.status === true) {
                    toast.success(response.message);
                    setTimeout(() => {
                        Navigate('/dashboard/faq')
                    }, 1000);
                } else {
                    toast.warn(response.message);
                }
            } catch (error) {
                toast.error('Error: ' + error.message);
            }
        }
    });

    const handleQuillChange = (content, delta, source, editor) => {
        formik.setFieldValue('faqDescription', content);
    };
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', 'video', 'formula'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ],
    };

    return (
        <div>
            <div className='App'>
            <ToastContainer />
                <div className='container-fluid'>
                    <div className='custom-faq-addblogs'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='row'>
                                        <div className='col-lg-2'>
                                            <Dashboard />
                                        </div>
                                        <div className='col-md-12 ms-sm-auto col-lg-10 px-md-4 m-0 p-0'>
                                            <div className='custom-inside-addblog'>
                                                <div className='row '>
                                                    <div className='col-lg-10'>
                                                        <h2><a href='/dashboard/faq'><IoArrowBackCircleOutline className='me-2' style={{color:"rgb(235 138 152)"}}/></a> Add Blogs</h2>
                                                        <div className='custom-addblogs-1'>
                                                            <form  onSubmit={formik.handleSubmit}>
                                                                <div className="row mb-3">
                                                                    <label htmlFor="faqTitle" className="col-sm-3 col-form-label text-end">Faq Title</label>
                                                                    <div className="col-sm-9">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control input-text-2"
                                                                            id="faqTitle"
                                                                            name="faqTitle"
                                                                            value={formik.values.faqTitle}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            placeholder='Faq Title'
                                                                        />
                                                                          {formik.touched.faqTitle && formik.errors.faqTitle ? (
                                                                            <div className="text-danger">{formik.errors.faqTitle}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                           
                                                                <div className="row mb-3">
                                                                    <label htmlFor="faqDescription" className="col-sm-3 col-form-label text-end">Faq Description</label>
                                                                    <div className="col-sm-9 custom-edit-1">
                                                                        <ReactQuill
                                                                            className="custom-quill"
                                                                            ref={quillRef}
                                                                            style={{ width: '100%' }}
                                                                            value={formik.values.faqDescription}
                                                                            onChange={handleQuillChange}
                                                                            modules={modules}
                                                                            placeholder="Write something..."
                                                                        />
                                                                         {formik.touched.faqDescription && formik.errors.faqDescription ? (
                                                                            <div className="text-danger">{formik.errors.faqDescription}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className='text-center'>
                                                                    <button className='liquidity-btn-1 mt-4' type="submit">Add</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Faqaddblogs