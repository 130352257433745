import React, { useState, useEffect, useRef } from 'react';
import skin from "../Assets/images/skin2-img10.webp";
import DataTable from 'react-data-table-component';
import { RiDeleteBin6Line } from "react-icons/ri";
import { makeApiRequest } from "../AxiosServices/apiCall"
import { toast, ToastContainer } from "react-toastify"
import Select from 'react-select';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

function Category() {

  const [defaultData, setDefaultData] = useState({ id: "", name: "" })
  const [categoryList, setCategoryList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const [categoryId, setCategoryId] = useState("")
  const [subCategoryId, setSubCategoryId] = useState("")
  const [categoryStatus, setCategoryStatus] = useState("")
  const [type, setType] = useState("")
  const [isOpenSkinCare, setIsOpenSkinCare] = useState(false);
  const [selectedSkinCareOption, setSelectedSkinCareOption] = useState(defaultData.name);
  const [selectedCategory, setSelectedCategory] = useState(defaultData.name);
  const [clickedData, setClickedData] = useState(null);
  const [userName, setUserName] = useState("")
  const [userCategorie, setUserCategorie] = useState("")
  const [categoryImage, setCategoryImage] = useState("")
  const handleToggleSkinCare = () => setIsOpenSkinCare(prevIsOpen => !prevIsOpen);
  const modalRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleChange = (option) => {
    setSelectedOption(option.id);
    setCategoryId(option.id)
  };

  const handleDelete = () => {
    console.log("categoryId", categoryId);

    setType("deleteCategory")
    setClickedData(categoryId)
  }

  const handleSelectSkinCare = (value, id) => {

    if (!value) {
      toast.error('Please select an option');
    } else {
      setSelectedSkinCareOption(value);
      setCategoryId(id)
      setIsOpenSkinCare(false);
      setSelectedCategory(value);
    }

  };

  const skinCareOptions = categoryList.map((data) => {
    return {
      value: data.name,
      label: data.name,
      imgSrc: skin,
      id: data._id
    }
  })

  function CustomDropdown({ options, isOpen, selectedOption, handleToggle, handleSelect, defaultLabel }) {
    return (
      <div className="custom-dropdown">
        <div className="dropdown-header" onClick={handleToggle}>
          <img
            src={options.find(option => option.value === selectedOption)?.imgSrc || skin}
            alt="Selected option"
            className="dropdown-image"
          />
          <span>{options.find(option => option.value === selectedOption)?.label || defaultLabel}</span>
          <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`} />
        </div>
        {isOpen && (
          <div className="dropdown-list">
            {options.map((option) => (
              <div
                key={option.value}
                className="dropdown-item"
                onClick={() => handleSelect(option.value, option.id)}
              >
                <img src={option.imgSrc} alt={option.label} className="dropdown-image" />
                <span>{option.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  const fetchCategorieList = async () => {
    try {
      let params = {
        url: "getcategories",
        method: "GET"
      };
      const response = await makeApiRequest(params);
      if (response.status) {
        const { data: categoriesList } = response;
        const { _id: listID, name: listName } = categoriesList[0];
        setDefaultData({ ...defaultData, id: listID, name: listName });
        setCategoryList(categoriesList);
        const selectedCategoryData = categoriesList.find(({ name }) => name === selectedCategory);
        const defaultCategory = categoriesList.find(({ name }) => name === listName);
        if (!selectedCategoryData) {
          setCategoryId(listID);
          setSelectedCategory(listName);
          setSelectedSkinCareOption(listName);
          setSubCategoryList(defaultCategory.subCategories);
        } else {
          setCategoryId(selectedCategoryData._id);
          setSelectedCategory(selectedCategoryData.name);
          setSubCategoryList(selectedCategoryData.subCategories);
        }
      } else {
        setCategoryList([]);
        setSubCategoryList([]);
      }
    } catch (error) {
      // toast.error(selectedCategory ? "Something Went Wrong...." : "No Data Found");
    }
  };

  useEffect(() => {
    fetchCategorieList()
    if (clickedData && (type === 'updateStatus' || type == "" || type == "addImage" || type == "deleteCategory")) {
      handleSubmit(clickedData);
    }
  }, [selectedCategory, clickedData, type])

  const columnsone = [
    {
      name: 'S.NO',
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      sortable: true,
    },
    {
      name: 'Sub Category',
      selector: row => row.category,
      sortable: true,
    },
    {
      name: 'Product status',
      selector: row => row.button,
      sortable: true,
      width: "150px",
    },
    {
      name: 'Upload Image',
      selector: row => row.image,
      sortable: true,
      width: "350px",
    },
    {
      name: 'Image Preview',
      selector: row => row.preview,
      sortable: true,
      width: "150px",
    },
    {
      name: 'Action',
      selector: row => row.status,
      sortable: true,
    },
  ];

  const clearForm = () => {
    setUserName("");
    setUserCategorie("");
  };

  const handleSubmit = async () => {
    if (isLoading) return; // Prevent double click
    setIsLoading(true);
    if (type == "deleteCategory" || type == "") {
      if (type === "deleteCategory" && !selectedOption) return toast.error("Select a Category to Delete...");
      try {
        const swalResult = await Swal.fire({
          title: "Are you sure you want to Delete?",
          icon: "question",
          iconHtml: "?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
          showCloseButton: true
        });
        console.log(type, "type");

        console.log(swalResult.isConfirmed, "swalResult.isConfirmed");

        if (swalResult.isConfirmed) {

          console.log(swalResult.isConfirmed, "if swalResult");

          setIsLoading(true);
          const requestData = {
            type,
            name: userName,
            subCategories: userCategorie,
            status: Number(categoryStatus),
            categoryId,
            subCategoryId,
            Images: categoryImage || "",
          };

          const params = {
            url: "addCategories",
            method: "POST",
            data: requestData,
            header: "image",
          };

          const response = await makeApiRequest(params);

          if (response.status) {
            toast.success(response.message);
            clearForm();
            fetchCategorieList();
            const modalElement = modalRef.current;
            const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
            if (modalInstance) {
              modalInstance.hide();
            }
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          } else {
            setIsLoading(false)
            toast.error(response.message);
          }
        }
        else {
          window.location.reload();

        }
      } catch (error) {
        toast.error("Something went wrong.");
      }
    } else {
      try {
        if (!userName && type === "add") return toast.error("Enter the Product Name...");
        if (!userCategorie && (type === "add" || type === "addSub")) return toast.error("Enter the Sub-Category...");

        setIsLoading(true);
        const requestData = {
          type,
          name: userName,
          subCategories: userCategorie,
          status: Number(categoryStatus),
          categoryId,
          subCategoryId,
          Images: categoryImage || "",
        };

        const params = {
          url: "addCategories",
          method: "POST",
          data: requestData,
          header: "image",
        };

        const response = await makeApiRequest(params);

        if (response.status) {
          toast.success(response.message);
          clearForm();
          fetchCategorieList();
          const modalElement = modalRef.current;
          const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
          if (modalInstance) {
            modalInstance.hide();
          }
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          // setIsLoading(false)
          toast.error(response.message);
        }
      } catch (error) {
        toast.error("Something went wrong.");
      } finally {
        setIsLoading(false);
      }
    }

  };


  const handleButtonClick = (data) => {
    setSubCategoryId(data._id);
    setClickedData({ subCategoryId: data._id });
  };

  const handleImageChange = async (event, data) => {
    setType("addImage")
    setSubCategoryId(data._id)

    const files = event.target.files;

    // Check if more than one image is selected
    if (files.length > 1) {
      toast.warn('You can only upload a single image.');
      return;
    }

    const file = files[0];

    // Check file format
    const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!allowedFormats.includes(file.type)) {
      toast.error('Invalid file format. Only JPG, JPEG, and PNG are allowed.');
      return;
    }

    // Check file size (2MB)
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
    if (file.size > maxSizeInBytes) {
      toast.error('File size too large. Maximum allowed size is 2MB.');
      return;
    }

    // Check image dimensions (1024x1024)
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      
        // If all checks pass, set the image
        setCategoryImage(file);
        setClickedData({ subCategoryId: data._id, image: file });
      
    };

    img.onerror = () => {
      toast.error('There was an error loading the image.');
    };
  };


  const skinCareData = subCategoryList.map((data, index) => {
    return {
      id: data._id,
      name: index + 1,
      category: data.name,
      button: (
        <div className='d-flex'>
          {isLoading == true ? (
            <button className="liquidity-btn-1" >
              <Spinner animation="border" />
            </button>
          ) : (
            <button
              className='liquidity-btn-1 me-3'
              onClick={() => {
                setType("updateStatus");
                setCategoryStatus(data.status == 1 ? "0" : "1");
                handleButtonClick(data);
              }}
            >
              {data.status == 1 ? "Enable" : "Disable"}
            </button>
          )}
        </div>
      ),
      image: (
        <div>
          <div class="input-group">
            {isLoading == true ? (
              <Spinner animation="border" />
            ) : (
              <input type="file" class="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                onChange={(event) => handleImageChange(event, data)}
              />
            )}
          </div>
        </div>
      ),
      preview: (
        <div className='custom-img-pre'>
          <img style={{ width: "50px" }} src={data.image} />
        </div>
      ),
      status: (
        <div className='d-flex align-items-center'>
          {isLoading == true ? (
            <Spinner animation="border" />
          ) : (
            <RiDeleteBin6Line
              style={{ "cursor": "pointer" }}
              onClick={() => {
                setType("");
                handleButtonClick(data);
              }}
            />
          )}
        </div>
      ),
    }
  })

  return (

    <div className='App'>
      <ToastContainer />
      <div className='container-fluid'>
        <div className='custom-category'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='custom-inside-category'>
                <h2 className='fw-bold'>Category</h2>
                <div>
                  {isLoading == true ? (
                    <button className="liquidity-btn-2" >
                      <Spinner animation="border" />
                    </button>
                  ) : (
                    <button type="button" className="liquidity-btn-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop-category"
                      onClick={() => {
                        setType("add");
                        clearForm();
                      }}>
                      Add Category
                    </button>
                  )}
                  <button className="liquidity-btn-2 ms-4" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop-delect">Delete category</button>
                  <div class="modal fade" id="staticBackdrop-delect" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel-delect" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header border-0">
                          <h1 class="modal-title fs-5" id="staticBackdropLabel-delect">Delete category</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div className='row'>
                            <div className='col-lg-8'>
                              <form>
                                <Select
                                  options={skinCareOptions}
                                  value={skinCareOptions.find(option => option.id === selectedOption)}
                                  onChange={handleChange}
                                />
                              </form>
                            </div>
                            <div className='col-lg-4'>
                              {isLoading == true ? (
                                <button className="liquidity-btn-2" >
                                  <Spinner animation="border" />
                                </button>
                              ) : (
                                <button type="button" class="liquidity-btn-2" onClick={() => handleDelete()}>Delete</button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row align-items-center'>
                  <div className='col-lg-6'>
                    <div className='row mb-3'>
                      <div className="col-sm-6 mt-4">
                        <CustomDropdown
                          options={skinCareOptions}
                          isOpen={isOpenSkinCare}
                          selectedOption={selectedSkinCareOption}
                          handleToggle={handleToggleSkinCare}
                          handleSelect={handleSelectSkinCare}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 text-end'>
                    <div>
                      {isLoading == true ? (
                        <button className="liquidity-btn-2" >
                          <Spinner animation="border" />
                        </button>
                      ) : (
                        <button type="button" class="liquidity-btn-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop-1"
                          onClick={() => { setType("addSub"); clearForm(); }}>
                          Add Sub Category
                        </button>
                      )}
                      <div class="modal fade text-dark" id="staticBackdrop-1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel-1" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h1 class="modal-title fs-5" id="staticBackdropLabel-1">Add Sub Category</h1>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <div class="modal-body">
                              <div className='mt-3'>
                                <form>
                                  <div className="mb-3 row">
                                    <label htmlFor="category" className="col-sm-2 col-form-label text-end">
                                      Category
                                    </label>
                                    <div className="col-sm-10">
                                      <input type="text" className="form-control" id="category"
                                        placeholder='Sub-Categorie Name...'
                                        // value={userCategorie || ""}
                                        onChange={(e) => setUserCategorie(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="modal-footer justify-content-center">
                              {isLoading == true ? (
                                <button className="liquidity-btn-2" >
                                  <Spinner animation="border" />
                                </button>
                              ) : (
                                <button type="button" class="liquidity-btn-2"
                                  onClick={handleSubmit}
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal fade" id="staticBackdrop-category" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel-category" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header border-0">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel-category">Add Category</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body border-0">
                        <form>
                          <div className="mb-3 row">
                            <label htmlFor="name" className="col-sm-4 col-form-label text-end">
                              Name
                            </label>
                            <div className="col-sm-8">
                              <input type="text" className="form-control" id="name"
                                placeholder='Product Name...'
                                // value={userName || ""}
                                onChange={(e) => setUserName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <label htmlFor="category" className="col-sm-4 col-form-label text-end">
                              Sub Category
                            </label>
                            <div className="col-sm-8">
                              <input type="text" className="form-control" id="category"
                                placeholder='Sub-Categorie Name...'
                                // value={userCategorie || ""}
                                onChange={(e) => setUserCategorie(e.target.value)}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="modal-footer justify-content-center border-0">
                        {isLoading == true ? (
                          <button className="liquidity-btn-2" >
                            <Spinner animation="border" />
                          </button>
                        ) : (
                          <button type="button" className="liquidity-btn-2"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {selectedCategory ? (
                  <div className='mb-1'>
                    <h5>{selectedCategory}</h5>
                    <div className='liquidity-table-1 mt-3'>
                      <DataTable
                        columns={columnsone}
                        data={skinCareData}
                        theme="solarized"
                        defaultSortAsc={true}
                        pagination
                        paginationTotalRows={categoryList.length}
                        paginationPerPage={rowsPerPage}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                        onChangePage={handlePageChange}
                        highlightOnHover
                        dense
                      />
                    </div>
                  </div>
                ) : (
                  <div className='liquidity-table-1 mt-3'>
                    <DataTable
                      columns={columnsone}
                      data={skinCareData}
                      theme="solarized"
                      defaultSortAsc={true}
                      pagination
                      paginationTotalRows={categoryList.length}
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[5, 10, 15, 20]}
                      onChangeRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
                      onChangePage={handlePageChange}
                      highlightOnHover
                      dense
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;
