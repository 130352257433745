import React, { useState, useEffect, } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeApiRequest } from "../AxiosServices/apiCall";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from 'react-bootstrap';


function Gst() {

    const [initialValues, setInitialValues] = useState({
        ShippingCharge: '',
        tax_Rate: '',
        tax_Type: '',
    });

    const [initialValues1, setInitialValues1] = useState({
        siteName: '',
        siteYear: '',
        email: '',
        mobile: '',
        address: '',
    });
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);


    const getsiteSetting = async () => {
        try {
            let params = {
                url: "getsiteSetting",
                method: 'GET',
            }
            let response = await makeApiRequest(params);
            // console.log('response--',response);

            if (response.status) {
                setInitialValues({
                    ShippingCharge: response.data[0].ShippingCharge,
                    tax_Rate: response.data[0].tax_Rate,
                    tax_Type: response.data[0].tax_Type,
                });

            } else {
                setInitialValues({
                    ShippingCharge: '',
                    tax_Rate: '',
                    tax_Type: '',
                });
            }
        } catch (error) {
            console.log('error--', error);
        }

    }

    const getAddressSetting = async () => {
        try {
            let params = {
                url: "getAddressSetting",
                method: 'GET',
            }
            let response = await makeApiRequest(params);
            // console.log('getAddressSetting--',response);

            if (response.status == true) {
                setInitialValues1({
                    siteName: response.data[0].siteName,
                    siteYear: response.data[0].siteYear,
                    email: response.data[0].email,
                    mobile: response.data[0].mobile,
                    address: response.data[0].address
                });

            } else {
                setInitialValues1({
                    siteName: '',
                    siteYear: '',
                    email: '',
                    mobile: '',
                    address: '',
                });
            }
        } catch (error) {
            console.log('error--', error);
        }

    }
    useEffect(() => {
        getsiteSetting();
        getAddressSetting();
    }, [])

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object({

            tax_Rate: Yup.number()
                .typeError('*Taxrate must be a number') // Ensure the type is a number
                .positive('*Taxrate must be a positive number')
                .min(0, '*Taxrate must be a positive number') // Ensure it is greater than 0
                .required('*Taxrate is required'),
            tax_Type: Yup.string().required('*Tax Type is required'),
            ShippingCharge: Yup.number()
                .typeError('*ShippingCharge must be a number') // Ensure the type is a number
                .positive('*ShippingCharge must be a positive number')
                .min(0, '*ShippingCharge must be a positive number') // Ensure it is greater than 0
                .required('*ShippingCharge is required'),


        }),
        onSubmit: async (values) => {

            const formData = new FormData();
            formData.append('ShippingCharge', values.ShippingCharge);
            formData.append('tax_Rate', values.tax_Rate);
            formData.append('tax_Type', values.tax_Type);


            try {
                setLoading(true);
                const params = {
                    url: "addSiteSetting",
                    method: "POST",
                    data: formData,
                };

                const response = await makeApiRequest(params);
                if (response.success) {
                    toast.success('GST settings updated successfully');
                    setLoading(false);
                } else {
                    toast.warn(response.message);
                    setLoading(false);
                }
            } catch (error) {
                console.log("error", error);
            }
        }
    });

    const formik1 = useFormik({
        initialValues: initialValues1,
        enableReinitialize: true,
        validationSchema: Yup.object({

            siteName: Yup.string().required('*Site Name is required'),
            siteYear: Yup.string()
                .matches(/^[0-9]+$/, '*Site Year must contain only numbers')
                .required('*Site Year is required'),
            email: Yup.string()
                .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, '*Invalid email format')
                .required('*Email is required'),
            mobile: Yup.string()
                .matches(/^[0-9]{10}$/, '*Mobile must be a valid 10-digit number')
                .required('*Mobile is required'),
            address: Yup.string().required('*Address is required'),


        }),
        onSubmit: async (values) => {
            console.log("workinggggggggggg");

            const formData = new FormData();
            formData.append('siteName', values.siteName);
            formData.append('siteYear', values.siteYear);
            formData.append('email', values.email);
            formData.append('mobile', values.mobile);
            formData.append('address', values.address);

            try {
                setLoading1(true);
                const params = {
                    url: "addAddressSetting",
                    method: "POST",
                    data: formData,
                };

                const response = await makeApiRequest(params);
                // console.log("response===",response);

                if (response.status == true) {
                    toast.success(response.message);
                    setLoading1(false);
                } else {
                    toast.warn(response.message);
                    setLoading1(false);
                }
            } catch (error) {
                console.log("error", error);
            }
        }
    });

    return (
        <div className='App'>
            <ToastContainer />
            <div className='custom-userlist'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='row'>
                            <div className='col-lg-8'>
                                <div className='custom-inside-gst'>
                                    <h2 className='fw-bold'> Site Setting</h2>
                                    <div className='mt-2 custom-inside-gst-1'>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="mb-3 row">
                                                <label className="col-sm-2 col-form-label">Shipping</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        name="ShippingCharge"
                                                        className="form-control input-text-2"
                                                        value={formik.values.ShippingCharge}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.errors.ShippingCharge ? (
                                                        <div className="text-danger">{formik.errors.ShippingCharge}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label className="col-sm-2 col-form-label">Tax Rate</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        name="tax_Rate"
                                                        className="form-control input-text-2"
                                                        value={formik.values.tax_Rate}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.errors.tax_Rate ? (
                                                        <div className="text-danger">{formik.errors.tax_Rate}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label className="col-sm-2 col-form-label">Tax Type</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        name="tax_Type"
                                                        className="form-control input-text-2"
                                                        value={formik.values.tax_Type}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.errors.tax_Type ? (
                                                        <div className="text-danger">{formik.errors.tax_Type}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='text-center'>
                                                {loading == true ? (
                                                    <button className="liquidity-btn-1" disabled>
                                                        <Spinner animation="border" />
                                                    </button>
                                                ) : (
                                                    <button className='liquidity-btn-1 mt-4' type="submit">Submit</button>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='col-lg-12'>
                        <div className='row'>
                            <div className='col-lg-8 mt-3'>
                                <div className='custom-inside-gst'>
                                    <h2 className='fw-bold'>Site Address</h2>
                                    <div className='mt-2 custom-inside-gst-1'>
                                        <form onSubmit={formik1.handleSubmit}>
                                            <div className="mb-3 row">
                                                <label className="col-sm-2 col-form-label">Site Name</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        name="siteName"
                                                        className="form-control input-text-2"
                                                        value={formik1.values.siteName}
                                                        onChange={formik1.handleChange}
                                                    />
                                                    {formik1.errors.siteName ? (
                                                        <div className="text-danger">{formik1.errors.siteName}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label className="col-sm-2 col-form-label">Site Year</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="number"
                                                        name="siteYear"
                                                        className="form-control input-text-2"
                                                        value={formik1.values.siteYear}
                                                        onChange={formik1.handleChange}
                                                    />
                                                    {formik1.errors.siteYear ? (
                                                        <div className="text-danger">{formik1.errors.siteYear}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="mb-3 row">
                                                <label className="col-sm-2 col-form-label">Email</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control input-text-2"
                                                        value={formik1.values.email}
                                                        onChange={formik1.handleChange}
                                                    />
                                                    {formik1.errors.email ? (
                                                        <div className="text-danger">{formik1.errors.email}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label className="col-sm-2 col-form-label">Mobile</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        type="text"
                                                        name="mobile"
                                                        className="form-control input-text-2"
                                                        value={formik1.values.mobile}
                                                        onChange={formik1.handleChange}
                                                    />
                                                    {formik1.errors.mobile ? (
                                                        <div className="text-danger">{formik1.errors.mobile}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label className="col-sm-2 col-form-label">Address</label>
                                                <div className="col-sm-10">
                                                    <textarea
                                                        name="address"
                                                        className="form-control input-text-2"
                                                        value={formik1.values.address}
                                                        onChange={formik1.handleChange}
                                                        rows="4" // Adjust the number of rows as needed
                                                    />
                                                    {formik1.errors.address ? (
                                                        <div className="text-danger">{formik1.errors.address}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='text-center'>
                                                {loading1 == true ? (
                                                    <button className="liquidity-btn-1" disabled>
                                                        <Spinner animation="border" />
                                                    </button>
                                                ) : (
                                                    <button className='liquidity-btn-1 mt-4' type="submit">Submit</button>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gst