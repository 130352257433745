import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Dashboard from './Dashboard';
import { makeApiRequest } from "../AxiosServices/apiCall";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Spinner } from 'react-bootstrap';




function Editbannercontent() {
    const location = useLocation();
    const { CategoryData } = location.state || {};
    const [category, setCategory] = useState([]);
    const { id } = useParams();
    const quillRef = useRef(null);
    const navigate = useNavigate();
    const [mainImagePreview, setMainImagePreview] = useState(null);
    const [loading, setLoading] = useState(false);  // Set initial loading state to false


    const { handleChange, handleSubmit, handleBlur, setFieldValue, values, setValues, errors, touched, validateForm } = useFormik({
        initialValues: {
            categoryId: CategoryData?.categoryId || '',
            categoryName: CategoryData?.categoryName || '',
            categoryImage: CategoryData?.categoryImage || '',
            image: CategoryData?.categoryImage || ''
        },
        validationSchema: Yup.object().shape({
            categoryName: Yup.string().required('*Category Name is required'),
            image: Yup.mixed()
                .test('fileRequired', '*A file is required', value => {
                    // Check if the value is a string (existing image URL)
                    if (typeof value === 'string' && value.trim() !== '') {
                        return true; // Skip validation if it's an existing image URL
                    }
                    // Check if a new file is uploaded (when no image URL is present)
                    return value ? !!value : false; // Fail if no file is selected or no existing image is available
                })
                .test('fileType', '*Unsupported file format. Only JPEG, PNG, and JPG are allowed', value => {
                    if (typeof value === 'string') {
                        return true; // Skip validation for existing image URLs
                    }
                    return value ? ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type) : true; // Check file type for new uploads
                })
                .test('fileSize', '*File is too large. Maximum size is 2 MB', value => {
                    if (typeof value === 'string') {
                        return true; // Skip validation for existing image URLs
                    }
                    return value ? value.size <= 2 * 1024 * 1024 : true; // Check file size for new uploads
                })
        }),
        onSubmit: async (values) => {

            const errors = await validateForm();
            if (Object.keys(errors).length > 0) {
                return; // Stop submission if there are validation errors
            }

            const div = document.createElement('div');
            div.innerHTML = values.categoryDescription;
            const textContent = div.textContent || div.innerText || '';

            if (!textContent.trim()) {
                setFieldValue('categoryDescription', '');
                // toast.error('Category Description is required');
                return;
            }
            

            const formData = new FormData();
            formData.append('id', id);
            formData.append('categoryId', values.categoryId);
            formData.append('categoryName', values.categoryName);
            formData.append('categoryImage', values.image);

            // console.log(formData, "formDataaaaaaaaaaaaa");

            try {
                setLoading(true);
                const params = {
                    url: `updatebannnercontent`,
                    method: "POST",
                    data: formData,
                    header: "image"
                };
                const response = await makeApiRequest(params);
                if (response.status === true) {
                    setLoading(false);
                    toast.success(response.message);
                    setTimeout(() => {
                        navigate('/dashboard/general');
                    }, 1000);
                } else {
                    toast.warn(response.message);
                }
            } catch (error) {
                toast.error('Error: ' + error.message);
            }
        }
    });

    const getCategories = async () => {
        try {
            let params = {
                url: `edithomedatacontent/${id}`,
                method: 'GET',
            };
            let response = await makeApiRequest(params);

            if (response.status === true) {
                setCategory(response.data);
                // console.log(response.data, "response.data");

            } else {
                setCategory([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        getCategories();
    }, [id]);

    useEffect(() => {
        // console.log("blogdata", CategoryData);

        if (CategoryData) {
            setValues({
                categoryId: CategoryData.categoryId,
                categoryName: CategoryData.categoryName,
                categoryImage: CategoryData.categoryImage,
                image: CategoryData.categoryImage
            });

            if (quillRef.current) {
                quillRef.current.getEditor().root.innerHTML = CategoryData.categoryDescription;
            }
            setMainImagePreview(CategoryData.categoryImage || null);
        }
    }, [CategoryData, setValues]);

    const handleCategoryChange = (e) => {
        const selectedCategoryName = e.target.value;
        const selectedCategory = category.find(c => c.categoryName === selectedCategoryName);

        if (selectedCategory) {
            setFieldValue('categoryName', selectedCategory.categoryName);
            setFieldValue('categoryId', selectedCategory.categoryId); // Update categoryId
        }
    };

    const handleFileChange = (event) => {
        const file = event.currentTarget.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setMainImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            setFieldValue('image', file);
        } else {
            setMainImagePreview(null);
        }
    };

    const handleRemoveImage = () => {
        setMainImagePreview(null);
        setFieldValue('image', false);

        document.getElementById('image').value = '';
    };

    

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            ['link', 'image', 'video', 'formula'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ],
    };

    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='custom-addblog min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <Dashboard />
                                </div>
                                <div className='col-md-12 ms-sm-auto col-lg-10 px-md-4 m-0 p-0'>
                                    <div className='custom-inside-addblog'>
                                        <div className='row justify-content-center align-items-center'>
                                            <div className='col-lg-10'>
                                                <h2><a href='/dashboard/general'><IoArrowBackCircleOutline className='me-2' style={{ color: "rgb(235 138 152)" }} /></a>Edit Banner Details</h2>
                                                <div className='custom-addblogs-1'>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="row mb-3">
                                                            <label htmlFor="categoryName" className="col-sm-3 col-form-label text-end">Category Name</label>
                                                            <div className="col-sm-9">
                                                                <select
                                                                    className="form-select input-text-2"
                                                                    id="categoryName"
                                                                    name="categoryName"
                                                                    value={values.categoryName}
                                                                    onChange={(e) => handleCategoryChange(e)}  // Use custom handler
                                                                    onBlur={handleBlur}
                                                                    disabled
                                                                >
                                                                    <option value="" disabled>Select Category</option>
                                                                    {category.length > 0 ? (
                                                                        category.map((i, index) => (
                                                                            <option key={index} value={i.categoryName}>
                                                                                {i.categoryName}
                                                                            </option>
                                                                        ))
                                                                    ) : (
                                                                        <option value="" disabled>No Categories Available</option>
                                                                    )}
                                                                </select>
                                                                {errors.categoryName && touched.categoryName && (
                                                                    <div className="text-danger">{errors.categoryName}</div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        
                                                        <div className="row mb-3">
                                                            <label htmlFor="image" className="col-sm-3 col-form-label text-end">Category Image</label>
                                                            <div className="input-group mb-3 col-sm-9" style={{ width: '75%' }}>
                                                                <input
                                                                    type="file"
                                                                    className="form-control input-text-2"
                                                                    id="image"
                                                                    name="image"
                                                                    accept="image/jpeg, image/png, image/jpg"
                                                                    // value={values.categoryImage}
                                                                    onChange={handleFileChange}
                                                                />
                                                            </div>
                                                            <div className='row'>
                                                                <label htmlFor="image" className="col-sm-3 col-form-label text-end"></label>
                                                                <div className='image-preview mt-3 col-lg-8 ms-1'>
                                                                    {mainImagePreview && (
                                                                        <div className='preview-container'>
                                                                            <img src={mainImagePreview} alt="Preview" className='img-thumbnail' />
                                                                            <button type="button" className="btn btn-danger btn-sm"
                                                                                onClick={handleRemoveImage}
                                                                            >
                                                                                <IoMdRemoveCircleOutline />
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                    {errors.image && touched.image && (
                                                                    <div className="text-danger">{errors.image}</div>
                                                                )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='text-center'>
                                                        {loading == true ? (
                                                            <button  className='liquidity-btn-1 mt-4' disabled>
                                                                <Spinner animation="border" />
                                                            </button>
                                                        ):(
                                                            <button type="submit" className='liquidity-btn-1 mt-4'>
                                                            Update
                                                        </button>
                                                        )}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Editbannercontent;
