import React, { useState, useRef, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeApiRequest } from "../AxiosServices/apiCall";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './Dashboard';
import Select from 'react-select';
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { IoArrowBackCircleOutline } from "react-icons/io5";

function AddTrendingCategories() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [subCategoryVideo, setSubCategoryVideo] = useState(null);
    const [subCategoryId, setSubCategoryId] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    // const [product, setProduct] = useState([]);
    // const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const fileInputRef = useRef(null);
    

    // Formik setup
    const formik = useFormik({
        initialValues: {
            subCategoryid: "",
            subCategoryname: "",
            subCategoryVideo: null,
            categoryId : "",
            categoryName : '',
        },
        validationSchema: Yup.object({
            subCategoryid: Yup.string().required('*Category ID is required'),
            subCategoryname: Yup.string().required('*SubCategory Name is required'),
            subCategoryVideo: Yup.mixed()
                .required('*Video File is required')
                .test(
                    "fileSize",
                    "Video size is too large (max 30MB)",
                    value => !value || (value && value.size <= 30 * 1024 * 1024) // max 10MB
                )
                .test(
                    "fileFormat",
                    "Unsupported Format (allowed: mp4, avi)",
                    value => value && ['video/mp4', 'video/avi'].includes(value.type)
                ),
        }),
        onSubmit: async (values) => {
            // console.log('Form values:', values);
            const formData = new FormData();
            formData.append('subCategoryid', values.subCategoryid);
            formData.append('subCategoryname', values.subCategoryname);
            formData.append('subCategoryVideo', values.subCategoryVideo);
            formData.append('categoryId', values.categoryId);
            formData.append('categoryName', values.categoryName);
            // console.log("FormData:", formData);

            try {
                setLoading(true);
                // return
                const params = {
                    url: `addTrendingcontent`,
                    method: "POST",
                    data: formData,
                    header: "image"
                };
                
                const response = await makeApiRequest(params);
                // return
                if (response) {
                    toast.success(response.message);
                    setTimeout(() => {
                        navigate('/dashboard/general');
                    }, 1000);
                } else {
                    toast.warn(response.message);
                }
            } catch (error) {
                console.log("error-",error);
            } finally {
                setLoading(false);
            }
        }
    });

    // Fetch categories list
    const fetchCategorieList = async () => {
        try {
            let params = {
                url: "getcategories",
                method: "GET"
            };
            const response = await makeApiRequest(params);
            if (response.status) {
                const { data: categoriesList } = response;
                setCategoryList(categoriesList);
            } else {
                setCategoryList([]);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const handleSelectSkinCare = (selectedValue) => {
        // console.log("selectedValue--",selectedValue);
        
        const { value, id, categoryName, categoryid } = selectedValue;
        setSelectedOption(selectedValue);
        setSubCategoryId(id);
        setSelectedCategory(value);
        formik.setFieldValue('subCategoryid', id);
        formik.setFieldValue('subCategoryname', value);
        formik.setFieldValue('categoryId', categoryid);
        formik.setFieldValue('categoryName', categoryName);
        // const filtered = product.filter(prod => prod.categoryId === id);
        // setFilteredProducts(filtered);
    };

    useEffect(() => {
        fetchCategorieList();
    }, []);

    // Map categories to options for the Select dropdown
    const skinCareOptions = categoryList.flatMap((category) => {
        return category.subCategories.map((subCategory) => ({
            value: subCategory.name,
            label: subCategory.name,
            id: subCategory._id,
            categoryName : category.name,
            categoryid: category._id
        }));
    });
    

    // Handle video input change
    const handleVideoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSubCategoryVideo(URL.createObjectURL(file)); // Preview video
            formik.setFieldValue('subCategoryVideo', file); // Set file in formik
        }
    };

    // Remove selected video
    const handleRemoveVideo = () => {
        setSubCategoryVideo(null);
        formik.setFieldValue('subCategoryVideo', null); 
        // console.log("subCategoryVideo---",subCategoryVideo);      
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <div className='App'>
            <ToastContainer />
            <div className='container-fluid'>
                <div className='col-lg-2'>
                    <Dashboard />
                </div>
                <div className='custom-addcategory min-vh-100'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-md-12 ms-sm-auto col-lg-10 px-md-4 m-0 p-0'>
                                    <div className='custom-inside-addcategory'>
                                        <div className='row '>
                                            <div className='col-lg-10'>
                                                <h2><a href='/dashboard/general'><IoArrowBackCircleOutline  className='me-2' style={{color:"rgb(235 138 152)"}}/></a> Add Trending Categories</h2>
                                                <div className="col-sm-4 mt-4"></div>
                                                <div className='custom-addcategory-1'>
                                                    <form onSubmit={formik.handleSubmit}>
                                                        <div className="mb-3 row">
                                                            <label htmlFor="category" className="col-sm-3 col-form-label text-end">SubCategory </label>
                                                            <div className="col-sm-9">
                                                                <Select
                                                                    options={skinCareOptions}
                                                                    value={selectedOption}
                                                                    onChange={handleSelectSkinCare}
                                                                    placeholder="Select SubCategory"
                                                                />
                                                                {formik.touched.subCategoryname && formik.errors.subCategoryname ? (
                                                                    <div className="text-danger">{formik.errors.subCategoryname}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <label htmlFor="subCategoryVideo" className="col-sm-3 col-form-label text-end">SubCategory Video</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="file"
                                                                    className="form-control input-text-2"
                                                                    id="subCategoryVideo"
                                                                    name="subCategoryVideo"
                                                                    ref={fileInputRef}
                                                                    accept='video/*'
                                                                    onChange={handleVideoChange}
                                                                />
                                                                {formik.touched.subCategoryVideo && formik.errors.subCategoryVideo ? (
                                                                    <div className="text-danger">{formik.errors.subCategoryVideo}</div>
                                                                ) : null}
                                                            </div>

                                                            <div className='row'>
                                                                <label htmlFor="subCategoryVideo" className="col-sm-3 col-form-label text-end"></label>
                                                                <div className='video-preview mt-3 col-lg-8 ms-1'>
                                                                    {subCategoryVideo && (
                                                                        <div className='preview-container'>
                                                                            <video width="320" height="240" controls>
                                                                                <source src={subCategoryVideo} type="video/mp4" />
                                                                                Your browser does not support the video tag.
                                                                            </video>

                                                                            <button
                                                                                className="btn btn-danger btn-sm"
                                                                                onClick={handleRemoveVideo}
                                                                            >
                                                                                <IoMdRemoveCircleOutline />
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='text-center'>
                                                            {loading == true ? (
                                                                <button className="liquidity-btn-1" disabled>
                                                                    <Spinner animation="border" />
                                                                </button>
                                                            ) : (
                                                                <button className='liquidity-btn-1 mt-4' type="submit">Add</button>
                                                            )}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddTrendingCategories;
